/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


import '../src'

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

import 'bootstrap'

import 'src/partials/elements/footer';
import 'src/partials/elements/cookies';
import 'src/partials/elements/loader';
import 'src/partials/elements/navbar';
import 'src/partials/elements/scroll_top';
import 'src/partials/forms/forms';
import 'src/partials/forms/inputs_radio';
import 'src/partials/forms/inputs_option_payment';
import 'src/application/application';

import 'src/views/home';
import 'src/views/index';

import 'src/partials/contents/contact';
import 'src/partials/contents/home_top';
