$(document).ready(function(){

    $(document).on('click','.form-group-payment-method .radio',function(){
      // Variables
      box=$(this).parents('.form-group-payment-method');
      box_input=$(this).parents('.box-input')
      pos="ball pos"+$(this).attr('data-pos');
      // Actions
      $('.form-group-payment-method .box-input').removeClass('active');
      $(box_input).addClass('active');
      $('.radio',box).removeClass('active');
      $(this).addClass('active');
      $('.label_radio',box).removeClass('active');
      $(this).next().addClass('active');
      $('.ball',box).removeClass().addClass(pos);
    });
});