// Functions Labels Chisato
// Activate Focus
function input_label_on(var1){
	if(!var1.hasClass("form-group-filled")){
		$(var1).addClass('form-group-filled');
	}
}
// Desactivate Focus
function input_label_off(var1){
	if(($('input',var1).val()=="")&&(!$('.form-input-group',var1).hasClass("form-group-input-ccv"))){
		$(var1).removeClass('form-group-filled');
	}
	else if($('textarea',var1).val()==""){
		$(var1).removeClass('form-group-filled');
	}
  else if($('.form-input-group-date select:nth-child(1) option:selected',var1).val()=="0"||$('.form-input-group-date select:nth-child(2) option:selected',var1).val()=="0" ||$('.form-input-group-date select:nth-child(3) option:selected',var1).val()=="0"){
		$(var1).removeClass('form-group-filled');
	}
  else if($('.form-input-group-ccv select:nth-child(1) option:selected',var1).val()=="0"||$('.form-input-group-ccv select:nth-child(2) option:selected',var1).val()=="0" ||$('.form-input-group-ccv input',var1).val()==""){
		$(var1).removeClass('form-group-filled');
	}
  else if($('.form-input-group-select select option:selected',var1).val()=="0"){
		$(var1).removeClass('form-group-filled');
	}
	else{
		input_label_on(var1);
	}
}

// Actions Labels Chisato
$(document).ready(function(){
  // Check inputs value
  $(".form-group,.form-group-textarea").each(function(index){
    input_label_off($(this));
  });
  // Input Focus ON
  $(document).on('focus','.form-group,.form-group-textarea',function(){
    input_label_on($(this));
  });
  // Input Focus OFF
  $(document).on('focusout','.form-group,.form-group-textarea',function(){
    input_label_off($(this));
  });
});