// Animations HOME (Top Header)
function newAnimationHome(){
  var word = $('.change-texts').map(function() {
    return $(this).text();
  });

  var element = $('.change-text')[0];
  var wordIndex = 1;

  var resetAnimation = function() {
    element.classList.remove('flip');
  }

  setInterval(function(){
      switch (wordIndex) {
        case 0:
          element.classList.add('flip');
          element.textContent = word[wordIndex];
          wordIndex = 1;
          setTimeout(resetAnimation, 1000);
        break;

        case 1:
          element.classList.add('flip');
          element.textContent = word[wordIndex];
          wordIndex = 2;
          setTimeout(resetAnimation, 1000);
        break;

        case 2:
          element.classList.add('flip');
          element.textContent = word[wordIndex];
          wordIndex = 3;
          setTimeout(resetAnimation, 1000);
        break;

        case 3:
          element.classList.add('flip');
          element.textContent = word[wordIndex];
          wordIndex = 4;
          setTimeout(resetAnimation, 1000);
        break;

        case 4:
          element.classList.add('flip');
          element.textContent = word[wordIndex];
          wordIndex = 0;
          setTimeout(resetAnimation, 1000);
        break;
      }
  },2000);
}
function load_element(){
	$('.load_element').each(function(){
		// Variables
		var element_top = $(this).offset().top;
		var topOfWindow = $(window).scrollTop();
		var bottomOfWindow = $(window).scrollTop()+$(window).height()-75;
		var animations = $(this).attr('data-animation');
		// Actions
		if(element_top < bottomOfWindow){
			$(this).addClass(animations);
		}
	});
// Actions
}
$(document).ready(function(){
  if($('.home').length>0){
    //animationHome();
    newAnimationHome();
	}
});
$(window).scroll(function() {
	load_element();
});

// Animate Navbar Scroll
$(document).ready(function(){
  $(window).scroll(function () {
    if($(window).scrollTop()>20) {
      $('body').addClass('navbar-bg-in').removeClass('navbar-bg-out');
    }
    else if($(window).scrollTop()<50&&(!$('body').hasClass('navbar-bg-out'))&&($('body').hasClass('navbar-bg-in'))&& $('.home').length>0){
      $('body').removeClass('navbar-bg-in').addClass('navbar-bg-out');
    }
  });
});
