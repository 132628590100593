$(document).ready(function(){
  $('#navbar_main').on('show.bs.collapse', function () {
    $('body').addClass('open-menu');
  })
  $('#navbar_main').on('hidden.bs.collapse', function () {
    $('body').removeClass('open-menu');
  })
});
$(window).resize(function() {
  if($('body').width() > 992 && $('body').hasClass('open-menu')){
    $('#navbar_main').collapse('hide');
    $('body').removeClass('open-menu')
  }
});
// Action Btn Email
$(document).ready(function(){
    // Scroll Down (Home_Top --> about_us / team_block / contact_us)
    if($('.home').length>0){

      $(".btn_about").on("click",function(e){
        e.preventDefault();
        var posicion=$(".about_us").offset().top;
        $("html,body").animate({scrollTop:posicion-60},1000);
        if($('body').hasClass('open-menu')){
          $('#navbar_main').collapse('hide');
          $('body').removeClass('open-menu');
        }
      });

      $(".btn_team").on("click",function(e){
        e.preventDefault();
        var posicion=$(".team_block").offset().top;
        $("html,body").animate({scrollTop:posicion-60},1000);
        if($('body').hasClass('open-menu')){
          $('#navbar_main').collapse('hide');
          $('body').removeClass('open-menu')
        }
      });

      $(".btn_contact").on("click",function(e){
        e.preventDefault();
        var posicion=$(".contact").offset().top;
        $("html,body").animate({scrollTop:posicion-60},1000);
        if($('body').hasClass('open-menu')){
          $('#navbar_main').collapse('hide');
          $('body').removeClass('open-menu')
        }
      });

    }
    // Redirection URL
    else{
      $(document).on('click',".btn_contact",function(){
        window.location.href = gon.locale == 'en' ? '/en/contact_us' : '/es/contact_us';
      });
    }
});
