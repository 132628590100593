// TODO change cookies app name
$(document).ready(function() {
  const cookies = document.querySelector('.cookies')

  if (!Cookies.get('zemobile_new_webpage_cookies_accepted')) {
    //Apply the logic for not show the cookies bar
  }

  document.querySelector('#btn_cookies').addEventListener('click', function() {
    //Apply the logic for hide the cookies bar
    Cookies.set('zemobile_new_webpage_cookies_accepted', 'true', { expires: 365 });
  });
});
