function footer_position(){
    main=$('.main-content').height();
    footer=$('body > .footer').height();
    navbar=$('body > nav').height();
    if($(window).height()>(main+navbar+footer+100)){
      $('body > footer').addClass('footer-fixed');
    }
    else{
      $('body > footer').removeClass('footer-fixed');
    }
}
$(window).resize(function(){
    footer_position();
});
$(document).ready(function(){
    footer_position();
});