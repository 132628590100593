$(document).ready(function(){
    // Animate Scroll Top (in-out)
    $(window).scroll(function () {
      if($(window).scrollTop()>100){
        $('.scroll_top').addClass('bottom-in').removeClass('bottom-out');
      }
      else if($(window).scrollTop()<100&&(!$('.scroll_top').hasClass('bottom-out'))&&($('.scroll_top').hasClass('bottom-in'))){
        $('.scroll_top').removeClass('bottom-in').addClass('bottom-out');
      }
    });
    // Scroll Up (Action)
    $(".scroll_top").on("click",function(){
      $('html, body').animate({scrollTop:0},"300");
    });
});