function footer_position_index(){
    h1=$('.main-index h1').height();
    container=$('.main-index .container').height();
    footer=$('.main-index .footer-index').height();
    if($(window).height()>(h1+container+footer)){
      $('.main-index > .footer-index').addClass('footer-fixed');
    }
    else{
      $('.main-index > .footer-index').removeClass('footer-fixed');
    }
}
$(window).resize(function(){
    footer_position_index();
});
$(document).ready(function(){
    footer_position_index();
});