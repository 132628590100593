
import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['form', 'field', 'terms', 'checkmark']

  connect(){
    this.checkTerms()
  }

  submit_form(event){
    event.preventDefault();
    if (this.validate_fields()){
      $('#modal_contact').modal('show')
      document.getElementById("modal_contact").querySelector('button').addEventListener('click', () => {
        $.post('/contacts', $('#new_contact').serialize())
      });
    }
  }

  checkTerms(){
    this.termsTarget.checked ? this.checkmarkTarget.classList.add('checked') : this.checkmarkTarget.classList.remove('checked')
  }

  validate_fields(){
    var valid = true;
    this.fieldTargets.forEach((field) => {
      this.removeError(field)
      if (field.value == ""){
        valid = false;
        field.insertAdjacentHTML('afterend', "<span class='error error-message'> "+field.previousElementSibling.lastChild.textContent+ " "+this.formTarget.dataset.error +" </span>")
      }
    });
    if (!this.terms_valid())
      valid = false;
    return valid;
  }

  removeError(field){
    if (field.nextElementSibling && field.nextElementSibling.classList.contains('error-message'))
      field.nextElementSibling.remove()
  }

  terms_valid(){
    this.removeError(document.querySelector('.form-group-terms'))
    if (this.termsTarget.checked && this.checkmarkTarget.classList.contains('checked')){
      return true;
    } else {
      document.querySelector('.form-group-terms').insertAdjacentHTML('afterend', "<span class='error-message terms-message'> "+this.termsTarget.dataset.error +" </span>")
      return false;
    }
  }

}
